<template>
  <div>
    <b-row>
      <h2 class="ml-2">ADD NEW ORIGIN STATE</h2>
    </b-row>
    <b-row class="mt-1 align-items-end">
      <b-col cols="5">
        <b-form-group label="Address">
          <vue-google-autocomplete
            id="welcome_location"
            ref="address_input"
            classname="input-form form-control fond-white"
            placeholder="Please type your address"
            :country="['us']"
            style="height: 35px !important"
            @placechanged="setState"
          />
        </b-form-group>
      </b-col>
      <b-col cols="5">
        <b-form-group label="State">
          <b-form-input
            :disabled="true"
            ref="stateInput"
            placeholder="Waiting for address input..."
            v-model="state"
          >
          </b-form-input>
        </b-form-group>
      </b-col>
      <b-col class="text-right" cols="2">
        <b-form-group>
          <b-button v-if="showButton" variant="primary" @click="addOriginState">
            <feather-icon icon="PlusIcon" size="15" />
            <span> ADD </span>
          </b-button>
        </b-form-group>
      </b-col>
    </b-row>
    <hr />
    <b-row>
      <h2 class="ml-2 mt-1 mb-0">LIST OF ORIGIN STATES</h2>
    </b-row>
    <b-card>
      <filter-slot
        :filter-principal="filterPrincipal"
        :total-rows="totalRows"
        :paginate="paginate"
        :start-page="startPage"
        :to-page="toPage"
        :filter="[]"
        @reload="$refs['OriginStateTable'].refresh()"
      >
        <template #table>
          <b-table
            ref="OriginStateTable"
            :items="myProvider"
            :fields="fields"
            :per-page="paginate.currentPage"
            :current-page="paginate.currentPage"
            no-border-collapse
            show-empty
            sticky-header="50vh"
            class="position-relative"
            table-class="text-nowrap"
            :busy.sync="isBusy"
          >
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle mr-1" />
                <strong>Loading...</strong>
              </div>
            </template>
            <template #cell(created_at)="data">
              <span> {{ data.item.created_at | myGlobalDay }} </span>
            </template>

            <template #cell(actions)="data">
              <feather-icon
                v-b-tooltip.hover.top="
                  data.item.status == 'Active' ? 'Deactivate' : 'Activate'
                "
                :icon="
                  data.item.status == 'Active'
                    ? 'ThumbsDownIcon'
                    : 'ThumbsUpIcon'
                "
                :class="
                  data.item.status == 'Active' ? 'text-danger' : 'text-success'
                "
                class="mr-1 cursor-pointer"
                size="15"
                @click="
                  changeStatus(
                    data.item.status == 'Active' ? 2 : 1,
                    data.item.id
                  )
                "
              />
              <feather-icon
                v-b-tooltip.hover.top="'Eliminar'"
                icon="TrashIcon"
                class="text-danger cursor-pointer"
                size="15"
                @click="deletionLogical(data.item.id)"
              />
            </template>
          </b-table>
        </template>
      </filter-slot>
    </b-card>
  </div>
</template>

<script>
import VueGoogleAutocomplete from "vue-google-autocomplete";
import OriginStatesFields from "@/views/correspondence/views/settings/data/origin-states.fields";
import SettingsService from "@/views/correspondence/views/settings/service/settings.service";
import { mapGetters } from "vuex";
export default {
  components: { VueGoogleAutocomplete },
  watch: {
    
  },
  data() {
    return {
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search...",
        model: "",
      },
      totalRows: 0,
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      startPage: 0,
      toPage: 0,
      isBusy: false,
      fields: OriginStatesFields,
      address: [],
      addressComplete: null,
      state: "",
      state_slug: "",
      showButton: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  methods: {
    myProvider: async function (ctx) {
      try {
        let params = {
          perpage: this.paginate.perPage,
          npage: this.paginate.currentPage,
          campo: this.filterPrincipal.model,
        };
        const { data, status } = await SettingsService.getOriginStatesPaginated(
          params
        );
        if (status === 200) {
          this.totalRows = data.total;
          if (data.from) {
            this.startPage = data.from;
          } else {
            this.startPage = 0;
          }

          if (data.to) {
            this.toPage = data.to;
          } else {
            this.toPage = 0;
          }
          return data.data || [];
        }
      } catch (error) {
        console.error(error);
      }
    },
    async addOriginState() {
      try {
        let params = {
          address: this.addressComplete.formatted_address
            ,
          slug: this.address.administrative_area_level_1,
          postal_code: this.address.postal_code,
          user_id: this.currentUser.user_id,
        };
        const resultState = await this.showConfirmSwal();
        if (resultState.isConfirmed) {
          this.addPreloader();
          const {status} = await SettingsService.addNewOriginState(params);
          if (status == 200) {
            this.showSuccessSwal("New origin state registered successfully!");
            this.$refs["OriginStateTable"].refresh();
            this.resetInputs();
          }
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    async changeStatus(statusToChange, idOriginState) {
      try {
        let params = {
          status: statusToChange,
          id_origin_state: idOriginState,
        };
        const result = await this.showConfirmSwal();
        if (result.isConfirmed) {
          this.addPreloader();
          const {data, status} = await SettingsService.changeStatusOriginState(
            params
          );
          if(data[0]){
            this.showInfoSwal(data[0].message)
          }else{
            if (status == 200) {
              this.showSuccessSwal("Status of state origin changed successfully")
              this.$refs["OriginStateTable"].refresh()
            }
          }
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    async deletionLogical(idOriginState) {
      try {
        let params = {
          origin_state_id: idOriginState,
          user_id: this.currentUser.user_id,
        };
        const result = await this.showConfirmSwal();
        if (result.isConfirmed) {
          this.addPreloader();
          const { status } = await SettingsService.deletionLogicalOriginState(
            params
          );
          if (status == 200) {
            this.showSuccessSwal();
            this.$refs["OriginStateTable"].refresh();
          }
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    setState(address, addressData) {
      //send the context for set `state` input
      const ctx = this
      if (address) {
        this.showButton = true;
      }
      this.address = address;
      //set the state in input
      addressData.address_components.filter(function (val){
        val.types.filter(function(sub){
          if(sub == 'administrative_area_level_1'){
            ctx.state = val.long_name
          }
        })
      })
      this.addressComplete = addressData
    },

    resetInputs() {
      this.$refs["address_input"].clear();
      this.showButton = false;
      this.state = "";
    },
  },
};
</script>