// FIELDS OF TABLE ORIGIN STATES
export default[
    {
        key: "address",
        visible: true,
    },
    {
        key: "state",
        visible: true,
    },
    {
        key: "slug",
        label: "STATE CODE",
        visible: true,
    },
    {
        key: "postal_code",
        visible: true
    },
    {
        key: "created_by",
        visible: true
    },
    {
        key: "created_at",
        visible: true
    },
    {
        key: "status",
        visible: true
    },
    {
        key: "actions",
        visible: true,
    },
]