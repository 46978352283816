<template>
  <div>
    <header-slot class="mb-2"> </header-slot>
    <div>
      <b-row>
        <b-col cols="2">
          <b-nav card-header pills vertical class="m-0">
            <b-nav-item
              :to="{ name: 'correspondence-origin-states' }"
              exact
              :exact-active-class="
                routerName == 'correspondence-origin-states' ? 'active' : ''
              "
              :link-classes="[bgTabsNavs]"
            >
              ORIGIN STATES
            </b-nav-item>
            <b-nav-item
              :to="{ name: 'correspondence-account-number' }"
              exact
              :exact-active-class="
                routerName == 'correspondence-account-number' ? 'active' : ''
              "
              :link-classes="[bgTabsNavs]"
            >
              ACCOUNT NUMBER
            </b-nav-item>
          </b-nav>
        </b-col>
        <b-col cols="10">
          <b-card class="p-2" no-body>
            <router-view :key="$route.name" />
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<style scoped>
.col-2{
  padding-right: 0 !important;
  margin-top: 5px !important;
}
.col-10{
  padding-left: 0 !important;
}
.card-header {
  padding-right: 0;
}
.tab-pane {
  padding-left: 0;
}
.tabs > div > ul {
  height: auto !important;
  width: auto !important;
  padding: 2 0px 2 0px !important;
}
.tabs > div > ul > li {
  width: 100% !important;
}
.nav-pills .nav-link {
  border-radius: 8px 0px 0px 8px !important;
}
</style>
<script>
import OriginState from "@/views/correspondence/views/settings/components/OriginState.vue";
import AccountNumber from "@/views/correspondence/views/settings/components/AccountNumber.vue";
export default {
  components: { OriginState, AccountNumber },
  computed: {
    routerName() {
      return this.$route.matched[2].name;
    },
  },
  methods: {
    isActiveTab(tab) {
      if (tab == 1 || this.routerName == "correspondence-origin-states") {
        return true;
      }
      if (tab == 2 && this.routerName == "correspondence-account-number") {
        return true;
      }
      return false;
    },
  },
};
</script>

